import React from "react"

const Care = () => {
  let careDetails = [
    {
      num: "01",
      ttl: "スーパーフレックス",
      txt: "CRISPには決められたコアタイムはありません。最良のアウトプットが出せるよう、自分が最も集中してパフォーマンスを高められる時間帯に働くことができます。",
    },
    {
      num: "02",
      ttl: "フルリモート",
      txt: "いつどこでどのように働くか、すべて自由です。性善説に基づいて仕事環境が配備されているため、あなたのやりたい時間と場所でクリエイティビティを発揮できます。",
    },
    {
      num: "03",
      ttl: "ダブルワーク",
      txt: "常に自分自身の能力を磨きCRISPの仕事をさらに良くしていきたいという意欲的なパートナーのために、CRISPでは業務に支障がない限りダブルワークも可能です。",
    },
  ]

  return (
    <section className="p-careers__common-care p-careers__farm-care">
      <div className="p-careers__common-care__wrapper">
        <div className="p-contents p-contents__care">
          <div className="p-contents__wrapper">
            <h2 className="c-ttl__en p-contents__en">CARE</h2>
            <div className="p-contents__block">
              <h3 className="c-ttl__main p-contents__ttl">
                より自由に働ける制度
              </h3>
            </div>
          </div>
        </div>
        <div className="p-careers__common-care__details">
          <ul className="p-careers__common-care__details-list">
            {careDetails.map(data => {
              return (
                <li
                  className="p-careers__common-care__details-item"
                  key={data.ttl}
                >
                  <span className="c-ttl__en p-careers__common-care__details-num">
                    {data.num}
                  </span>
                  <div className="p-careers__common-care__details-wrapper">
                    <h3 className="p-careers__common-care__details-ttl">
                      {data.ttl}
                    </h3>
                    <p className="c-txt__min p-careers__common-care__details-txt">
                      {data.txt}
                    </p>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </section>
  )
}

export default Care
