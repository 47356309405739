import React from "react"
import ArrowZ from "../../../common/ArrowZ"
import Img from "../../../common/Img"

const Inside = () => {
  let insideCommons = [
    {
      link: "https://note.com/crispsaladworks/n/n85777c25f8ab",
      src: "/asset/img/careers/inside/220729@2x.jpg",
      src2x: "/asset/img/careers/inside/220729@2x.jpg",
      srcWebp: "/asset/img/careers/inside/220729@2x.jpg.webp",
      srcWebp2x: "/asset/img/careers/inside/220729@2x.jpg.webp",
      alt: "【CRISP TGIF】やりがい、働くならどんな人が向いている？ 突撃レポート＆インタビュー",
      ttl: "【CRISP TGIF】やりがい、働くならどんな人が向いている？ 突撃レポート＆インタビュー",
    },
    {
      link: "https://note.com/crispsaladworks/n/nbe7997cf04b2",
      src: "/asset/img/careers/inside/220713@2x.jpg",
      src2x: "/asset/img/careers/inside/220713@2x.jpg",
      srcWebp: "/asset/img/careers/inside/220713@2x.jpg.webp",
      srcWebp2x: "/asset/img/careers/inside/220713@2x.jpg.webp",
      alt: "[CEO × CTO対談] CRISPエンジニアメンバーへの期待",
      ttl: "[CEO × CTO対談] CRISPエンジニアメンバーへの期待",
    },
    {
      link: "https://note.com/crispsaladworks/n/n823c9f27fea7",
      src: "/asset/img/careers/inside/220706@2x.jpg",
      src2x: "/asset/img/careers/inside/220706@2x.jpg",
      srcWebp: "/asset/img/careers/inside/220706@2x.jpg.webp",
      srcWebp2x: "/asset/img/careers/inside/220706@2x.jpg.webp",
      alt: "[CEO × CTO対談] CRISPエンジニアリングのカルチャー",
      ttl: "[CEO × CTO対談] CRISPエンジニアリングのカルチャー",
    },
    {
      link: "https://note.com/crispsaladworks/n/n4c15971724fd",
      src: "/asset/img/careers/inside/220701@2x.jpg",
      src2x: "/asset/img/careers/inside/220701@2x.jpg",
      srcWebp: "/asset/img/careers/inside/220701@2x.jpg.webp",
      srcWebp2x: "/asset/img/careers/inside/220701@2x.jpg.webp",
      alt: "[CEO × CTO対談] CRISPがエンジニアリングを内製化している理由",
      ttl: "[CEO × CTO対談] CRISPがエンジニアリングを内製化している理由",
    },
    {
      link: "https://note.com/crispsaladworks/n/n39d3a78fdec1",
      src: "/asset/img/careers/inside/220608@2x.jpg",
      src2x: "/asset/img/careers/inside/220608@2x.jpg",
      srcWebp: "/asset/img/careers/inside/220608@2x.jpg.webp",
      srcWebp2x: "/asset/img/careers/inside/220608@2x.jpg.webp",
      alt: "CRISP社員インタビュー 〜エンジニア 川本さん編〜",
      ttl: "CRISP社員インタビュー 〜エンジニア 川本さん編〜",
    },
    {
      link: "https://note.com/crispsaladworks/n/n6c3bea3d51f6",
      src: "/asset/img/careers/inside/211025@2x.jpg",
      src2x: "/asset/img/careers/inside/211025@2x.jpg",
      srcWebp: "/asset/img/careers/inside/211025@2x.jpg.webp",
      srcWebp2x: "/asset/img/careers/inside/211025@2x.jpg.webp",
      alt: "SaaS投資のプロが、サラダ専門店に投資した理由。",
      ttl: "SaaS投資のプロが、サラダ専門店に投資した理由。",
    },
  ]

  return (
    <section className="p-careers__common-inside p-careers__farm-inside">
      <div className="p-careers__common-inside__wrapper">
        <a
          className="js-hover p-careers__common-inside__link"
          data-hover-in-time="600"
          href="https://note.com/crispsaladworks/n/n4356c7a12280"
          target="_blank"
          rel="noopener noreferrer"
        >
          <div className="js-scroll__dark c-link__img p-careers__common-inside__img">
            <Img
              src="/asset/img/careers/farm/inside_main.jpg"
              src2x="/asset/img/careers/farm/inside_main@2x.jpg"
              webpSrc="/asset/img/careers/farm/inside_main.jpg.webp"
              webpSrc2x="/asset/img/careers/farm/inside_main@2x.jpg.webp"
              alt="元メルカリのエンジニアがサラダ専門レストランになぜ入社したのか。CRISPテクノロジー領域のスピアヘッド、Hiroki Wadaにその意外な理由を聞く。彼の考えるコネクティッド・レストランとは ─"
              imgClassName="p-careers__common-inside__img-item"
            />
          </div>
          <div className="p-careers__common-inside__header">
            <h2 className="c-ttl__en p-careers__common-inside__header-ttl">
              THE CRISP INSIDE
            </h2>
            <p className="p-careers__common-inside__header-txt">
              元メルカリのエンジニアがサラダ専門レストランになぜ入社したのか。CRISPテクノロジー領域のスピアヘッド、Hiroki
              Wadaにその強い思いを聞く。彼の考えるCRISPにとってのあるべきエンジニア像とは
              ─
            </p>
            <ArrowZ />
          </div>
        </a>
        <div className="p-careers__common-inside__body">
          <ul className="p-careers__common-inside__body-list">
            {insideCommons.map(data => {
              return (
                <li
                  className="p-careers__common-inside__body-item"
                  key={data.link}
                >
                  <a
                    className="js-hover p-careers__common-inside__body-item-link"
                    data-hover-in-time="600"
                    href={data.link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <div className="js-scroll__dark__sp c-link__img p-careers__common-inside__body-item-img">
                      <Img
                        src={data.src}
                        src2x={data.src2x}
                        alt={data.alt}
                        imgClassName="p-careers__common-inside__body-item-img-item"
                      />
                    </div>
                    <div className="p-careers__common-inside__body-item-content">
                      <h3 className="p-careers__common-inside__body-item-content-ttl">
                        {data.ttl}
                      </h3>
                    </div>
                  </a>
                </li>
              )
            })}
          </ul>
          <a
            className="js-hover p-careers__common-inside__body-link"
            data-hover-in-time="600"
            data-hover-out-time="650"
            href="https://note.com/crispsaladworks/m/m2a8de76fed4b"
            target="_blank"
            rel="noopener noreferrer"
          >
            <div className="c-link__main p-careers__common-inside__body-link-wrapper">
              <span className="p-careers__common-inside__body-link-span">
                <span className="c-link__main-inner">THE CRISP INSIDE</span>
                <ArrowZ />
              </span>
            </div>
          </a>
        </div>
      </div>
    </section>
  )
}

export default Inside
